import HandyCollapse from 'handy-collapse';

const mediaQuery = window.matchMedia('(max-width: 992px)');

export const initShortFaq = () => {
	const faqContainer = document.querySelector('.short-faq');

	if (!faqContainer) return;

	const faqAccordions = new HandyCollapse({
		nameSpace: mediaQuery.matches ? 'faq-mobile' : 'faq',
		activeClass: 'is-active',
		isAnimation: mediaQuery.matches ? true : false,
		closeOthers: true,
		animationSpeed: 800,
		cssEasing: 'ease',
	});

	faqAccordions.open('faq-1');
};
