import HandyCollapse from 'handy-collapse';
import mixitup from 'mixitup';
import SlimSelect from 'slim-select';

const $searchInput = document.querySelector('.faq-search__input');

function getActiveAccordion() {
	return document.querySelector('.faq-item__btn.is-active')?.getAttribute('data-faq-full-control');
}

const faqAccordions = new HandyCollapse({
	nameSpace: 'faq-full',
	activeClass: 'is-active',
	isAnimation: true,
	closeOthers: true,
	animationSpeed: 800,
	cssEasing: 'ease',
});

setTimeout(() => {
	faqAccordions.open('faq-1');
}, 500);

let mixer = '';
(function faqFilter() {
	const mixContainer = document.querySelector(`.faq__list`);
	if (!mixContainer) return;

	const mixFailInfo = document.querySelector('.faq__fail');
	const sectionSelectbox = document.querySelector(`.faq-filter__select`);

	mixer = mixitup(mixContainer, {
		load: {
			filter: 'all',
		},
		animation: {
			duration: 625,
			effects: 'fade translateY(20%)',
		},
		callbacks: {
			onMixFail: function (state) {
				if (mixFailInfo) {
					mixFailInfo.classList.add('is-active');
				}
			},
			onMixStart: function (state, futureState) {
				if (!futureState.hasFailed && mixFailInfo) {
					mixFailInfo.classList.remove('is-active');
				}

				if (futureState.totalShow === 0) {
					mixFailInfo.classList.add('is-active');
				}
			},
		},
	});

	new SlimSelect({
		select: sectionSelectbox,
		showSearch: false,
		onChange: (info) => {
			$searchInput.value = '';
			if (getActiveAccordion()) {
				mixer.filter(info.value);
				setTimeout(() => {
					faqAccordions.close(getActiveAccordion());
				}, 800);
			} else {
				mixer.filter(info.value);
			}
		},
	});
})();

(function simpleSearch() {
	const searchButton = document.querySelector('.faq-search__btn');
	if (!searchButton) return;
	const allAnswers = document.querySelectorAll('.faq-item__desc');
	let matching = [];
	let searchValueState = '';

	searchButton.addEventListener('click', () => {
		let searchValue = $searchInput.value.toLowerCase();

		if (searchValue !== searchValueState && searchValue.length > 3) {
			searchValueState = searchValue;
			allAnswers.forEach((desc) => {
				let currentDesc = desc.textContent.toLowerCase();
				let parentElement = desc.closest('.faq-item');

				if (currentDesc.match(searchValue) && matching.includes(parentElement) == false) {
					matching.push(parentElement);
				} else {
					matching = matching.filter((item) => item !== parentElement);
				}
			});

			if (getActiveAccordion()) {
				mixer.filter(matching);
				setTimeout(() => {
					faqAccordions.close(getActiveAccordion());
				}, 800);
			} else {
				mixer.filter(matching);
			}
		}
	});
})();
