import HandyCollapse from 'handy-collapse';

const mediaQuery = window.matchMedia('(max-width: 992px)');

const addClassOnScroll = () => {
	const homepageMenu = document.querySelector('.homepage-menu');
	const header = document.querySelector('.homepage-menu .searchbar-helper');

	if (!homepageMenu) return;
	const observer = new IntersectionObserver((changes) => {
		if (changes[0]?.isIntersecting === true || changes[1]?.isIntersecting === true) {
			homepageMenu.classList.remove('nav--scroll');
		} else {
			homepageMenu.classList.add('nav--scroll');
		}
	});

	observer.observe(header);
};

// Single location
const checkLocationHasDates = () => {
	const objectsNoResults = document.querySelector('.objects-no-results--without-dates');
	if (!objectsNoResults) return;

	function changeNoResultsStatus() {
		const popup = document.querySelector('.without-dates__popup'),
			mainElement = document.querySelector('body'),
			closePoupBtn = document.querySelector('.without-dates__popup-btn'),
			searchbarTooltip = document.querySelector('.searchbar__start-date-tooltip'),
			searchbarOpenBtn = document.querySelector('.without-dates__popup-searchbtn'),
			navSearchButton = document.querySelector('.nav__search'),
			searchbarNav = document.querySelector('.searchbar'),
			scrollOjectsLink = document.querySelector('.location-header__top-btn .btn').href;

		popup.classList.add('is-active');

		setTimeout(() => {
			searchbarTooltip.classList.add('is-active');
			searchbarNav.classList.add('popup-active');
		}, 1000);

		// Change button link inside nav
		const searchbarBtn = document.querySelector('.searchbar__btn'),
			hashFromHeaderLink = scrollOjectsLink.substring(scrollOjectsLink.indexOf('#') + 1),
			completeUrlWithHash = `${siteGlobalVariables.homeUrl}${window.location.pathname}#${hashFromHeaderLink}`;

		searchbarBtn.href = completeUrlWithHash;

		// Block scroll
		mainElement.classList.add('block-scroll');

		// Close popup
		closePoupBtn.addEventListener('click', () => {
			popup.classList.remove('is-active');
			mainElement.classList.remove('block-scroll');
			searchbarTooltip.classList.remove('is-active');
			searchbarNav.classList.remove('popup-active');
		});

		// Open check-in/check-out nav on mobile
		searchbarOpenBtn.addEventListener('click', () => {
			navSearchButton.click();
		});
	}

	const observer = new IntersectionObserver(
		(changes) => {
			if (changes[0]?.isIntersecting === true || changes[1]?.isIntersecting === true) {
				changeNoResultsStatus();

				observer.disconnect();
			}
		},
		{ threshold: 1 }
	);

	observer.observe(objectsNoResults);
};

const initHamburger = () => {
	const hamburgerOpenButton = document.querySelector('.nav__hamburger--open');
	const hamburgerCloseButton = document.querySelector('.nav__hamburger--close');
	const hamburgerMenu = document.querySelector('.hamburger');

	const toggleMenu = (hamburgerState) => {
		hamburgerState == 'open' ? hamburgerMenu.classList.add('is-open') : hamburgerMenu.classList.remove('is-open');
	};

	hamburgerOpenButton.addEventListener('click', () => {
		toggleMenu('open');
	});

	hamburgerCloseButton.addEventListener('click', () => {
		toggleMenu('close');
	});
};

const fixMenu = () => {
	const navContainer = document.querySelector('.main-nav');
	const nav = document.querySelector('.nav');
	navContainer.style.height = `${nav.clientHeight + 22}px`;
};

const submenus = () => {
	const submenuList = new HandyCollapse({
		nameSpace: 'submenu',
		activeClass: 'is-active',
		isAnimation: true,
		closeOthers: true,
		animationSpeed: 600,
		cssEasing: 'ease',
	});
};

const navFilters = () => {
	const filtersBtn = document.querySelector('.filters-btn');

	if (!filtersBtn || !mediaQuery.matches) return;
	new HandyCollapse({
		nameSpace: 'nav-filters',
		activeClass: 'is-active',
		isAnimation: true,
		closeOthers: true,
		animationSpeed: 600,
		cssEasing: 'ease',
	});
};

export const initMenu = () => {
	addClassOnScroll();
	checkLocationHasDates();
	initHamburger();
	fixMenu();
	submenus();
	navFilters();
};
