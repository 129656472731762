let SimpleLightbox = require('simple-lightbox');

function initVideos() {
	const allVideos = document.querySelectorAll('.gallery__video');

	if (!allVideos.length) return;
	allVideos.forEach((item) => {
		const videoUrl = item.getAttribute('data-link');
		if (videoUrl.includes('youtube')) {
			const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
			const match = videoUrl.match(regExp);

			item.setAttribute('href', `https://www.youtube.com/embed/${match[2]}?rel=0&autoplay=1`);
		} else {
			const regExp =
				/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;
			const match = videoUrl.match(regExp);

			item.setAttribute('href', `https://player.vimeo.com/video/${match[1]}`);
		}
	});
}

function initLightboxes(modal) {
	if (modal) {
		const lightboxContainer = document.querySelector('.object-modal .lightbox');
		if (!lightboxContainer) return;
		const lightboxItems = lightboxContainer.querySelectorAll('.lightbox-item');

		new SimpleLightbox({
			elements: lightboxItems,
		});
	} else {
		const lightboxContainer = document.querySelectorAll('.lightbox');
		if (!lightboxContainer) return;
		lightboxContainer.forEach((item) => {
			const lightboxItems = item.querySelectorAll('.lightbox-item');
			new SimpleLightbox({
				elements: lightboxItems,
			});
		});
	}
}

export const initLightboxesAndVideos = (modal = false) => {
	initVideos();
	initLightboxes(modal);
};
