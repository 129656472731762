import { gsap } from 'gsap';
import CSSRulePlugin from 'gsap/CSSRulePlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Power0 } from 'gsap';

gsap.registerPlugin(ScrollTrigger, CSSRulePlugin);

const bigImageBlock = () => {
	const bigImgContainer = document.querySelector('.big-image');

	if (!bigImgContainer) return;
	ScrollTrigger.matchMedia({
		'(min-width: 992px)': function () {
			gsap
				.timeline({
					scrollTrigger: {
						trigger: bigImgContainer,
						start: '-=30% center',
						end: 'top center',
					},
				})
				.from(
					'.big-image__picture',
					{
						clipPath: 'inset(0% 100% 0% 100%)',
						ease: Power0,
						duration: 1.5,
					},
					-1
				);
		},
	});
};

const mediumImageBlock = () => {
	const mediumImgContainer = document.querySelector('.medium-image');

	if (!mediumImgContainer) return;
	ScrollTrigger.matchMedia({
		'(min-width: 992px)': function () {
			gsap
				.timeline({
					scrollTrigger: {
						trigger: mediumImgContainer,
						start: '-=30% center',
						end: 'top center',
					},
				})
				.from(
					'.medium-image__picture',
					{
						clipPath: 'inset(0% 100% 0% 100%)',
						ease: Power0,
						duration: 1.5,
					},
					-1
				);
		},
	});
};

const shoesSlalomContact = () => {
	const cotanctContainer = document.querySelector('.our-locations');

	if (!cotanctContainer) return;
	ScrollTrigger.matchMedia({
		'(min-width: 992px)': function () {
			gsap
				.timeline({
					scrollTrigger: {
						trigger: cotanctContainer,
						endTrigger: '.our-locations__shoes',
						scrub: true,
					},
				})
				.from('.our-locations__shoes', {
					x: -300,
					y: 450,
				});
		},
	});
};

const instagramShoes = () => {
	const instagramContainer = document.querySelector('.instagram');

	if (!instagramContainer) return;
	ScrollTrigger.matchMedia({
		'(min-width: 992px)': function () {
			gsap
				.timeline({
					scrollTrigger: {
						trigger: instagramContainer,
						scrub: true,
						start: '-=20% bottom',
						end: '+=60% center',
					},
				})
				.from('.instagram__shoes', {
					x: 200,
					y: 150,
				});
		},
	});
};

const watermelonContactForm = () => {
	const cotanctFormContainer = document.querySelector('.contact-us');

	if (!cotanctFormContainer) return;

	ScrollTrigger.matchMedia({
		'(min-width: 992px)': function () {
			gsap
				.timeline({
					scrollTrigger: {
						trigger: cotanctFormContainer,
						scrub: true,
						end: 'bottom +=20%',
					},
				})
				.from('.contact-us__watermelon', {
					x: 400,
					y: -400,
				})
				.to('.contact-us__watermelon', {
					top: '80%',
				});
		},
	});
};

const twoColumnsGlasses = () => {
	const twoColumnsContainer = document.querySelector('.two-columns-event');

	if (!twoColumnsContainer) return;

	ScrollTrigger.matchMedia({
		'(min-width: 992px)': function () {
			gsap
				.timeline({
					scrollTrigger: {
						trigger: twoColumnsContainer,
						scrub: true,
						end: 'bottom +=20%',
					},
				})
				.from('.two-columns-event__glasses', {
					x: -300,
					y: 300,
					rotate: 360,
				})
				.to('.two-columns-event__glasses', {
					top: '80%',
					rotate: 0,
				});
		},
	});
};

const watermelonAskQuestion = () => {
	const askQuestionContainer = document.querySelector('.ask-question');

	if (!askQuestionContainer) return;

	ScrollTrigger.matchMedia({
		'(min-width: 992px)': function () {
			gsap
				.timeline({
					scrollTrigger: {
						trigger: askQuestionContainer,
						scrub: true,
						end: 'bottom +=20%',
					},
				})
				.from('.ask-question__watermelon', {
					x: 350,
					y: -350,
				})
				.to('.ask-question__watermelon', {
					top: '80%',
				});
		},
	});
};

const faqSearchSunbather = () => {
	const faqSearchContainer = document.querySelector('.faq-search');

	if (!faqSearchContainer) return;

	ScrollTrigger.matchMedia({
		'(min-width: 992px)': function () {
			gsap
				.timeline({
					scrollTrigger: {
						trigger: faqSearchContainer,
						end: 'bottom +=20%',
					},
				})
				.from('.faq-search__sunbather', {
					x: -100,
					y: 40,
					duration: 1.4,
				})
				.to(
					'.faq-search__sunbather',
					{
						autoAlpha: 1,
						duration: 1.2,
						delay: 0.2,
					},
					0
				);
		},
	});
};

export const initGsap = () => {
	bigImageBlock();
	mediumImageBlock();
	shoesSlalomContact();
	instagramShoes();
	watermelonContactForm();
	twoColumnsGlasses();
	watermelonAskQuestion();
	faqSearchSunbather();
};
