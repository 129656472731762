import AOS from 'aos';
import SweetScroll from 'sweet-scroll';

import { initMenu } from './modules/menu';
import { filterWithMixItUp } from './modules/filters';
import { globalMaps, locationsMap } from './modules/maps';
import { eventsSlider, locationHeaderSlider } from './modules/sliders';
import { initSearchbar } from './modules/searchbar';
import { initLightboxesAndVideos } from './modules/lightboxes';
import { initContactForm } from './modules/contact-form';
import {
	addClassToButtonPlaceOrder,
	initTicketsInfoTooltips,
	initWoocommerceInputs,
	initWoocommerceSelectboxes,
	redirectLocationCheckout,
} from './modules/woocommerce-forms';
import { initShortFaq } from './modules/accordions';
import { initCookies, pushParamsToSiteUrl } from './modules/cookies';
import {
	couponForm,
	renderDiscountModal,
	renderObjectModal,
	renderProductModal,
	renderRatingModal,
	renderUnitTypes,
	updateTotalPrice,
	updateTotalPriceEvent,
	saveBillingInputsToSession,
	saveMailchimpCheckboxToSession,
} from './modules/ajax';
import { initMessenger } from './modules/messenger';
import { initGsap } from './modules/gsap';
import { initInstagram } from './modules/instagram';

const page = document.querySelector('body');
const hash = window.location.hash;

const initAos = () => {
	AOS.init({
		once: true,
		duration: 1300,
		disable: 'mobile',
	});
};

function initSweetScroll() {
	const scroller = new SweetScroll({
		easing: 'easeOutSine',
		offset: -100,
		preventDefault: false,
	});
	const needsInitialScroll = document.getElementById(hash.substr(1)) != null;

	if (needsInitialScroll) {
		window.location.hash = '';
	}

	window.addEventListener(
		'load',
		() => {
			setTimeout(() => {
				scroller.to(hash, { updateURL: 'replace' });
			}, 500);
		},
		false
	);
}

const stopScrolling = () => {
	jQuery(document).ajaxComplete(function (e) {
		if (jQuery('body').hasClass('woocommerce-checkout') || jQuery('body').hasClass('woocommerce-cart')) {
			jQuery('html, body').stop();
		}
	});
};

document.addEventListener(
	'DOMContentLoaded',
	() => {
		const transition_el = document.querySelector('.preloader');
		transition_el.classList.remove('is-active');

		initInstagram();
		// initCookies();
		initSweetScroll();
		initAos();
		initMenu();
		initSearchbar();
		filterWithMixItUp('all-posts');
		filterWithMixItUp('all-trips');
		eventsSlider();
		initMessenger();
		initLightboxesAndVideos();
		initContactForm();
		initWoocommerceInputs();
		initWoocommerceSelectboxes();
		initShortFaq();
		initGsap();

		if (page.classList.contains('post-type-archive-our_locations')) {
			locationsMap();
			filterWithMixItUp('locations', true);
		} else if (page.classList.contains('page-template-template-search')) {
			pushParamsToSiteUrl();
			filterWithMixItUp('results', true);
		} else if (page.classList.contains('single-our_locations')) {
			pushParamsToSiteUrl();
			locationHeaderSlider();
			renderObjectModal(hash);
			renderUnitTypes();
			globalMaps();
		} else if (page.classList.contains('event-checkout')) {
			stopScrolling();
			updateTotalPriceEvent();
			initTicketsInfoTooltips();
		} else if (page.classList.contains('location-checkout')) {
			redirectLocationCheckout();
			stopScrolling();
			renderProductModal();
			updateTotalPrice();
			couponForm();
			saveBillingInputsToSession();
			saveMailchimpCheckboxToSession();
		} else if (page.classList.contains('woocommerce-checkout')) {
			addClassToButtonPlaceOrder();
		} else if (page.classList.contains('woocommerce-account')) {
			renderRatingModal();
			renderDiscountModal();
		} else {
			globalMaps();
		}
	},
	false
);
