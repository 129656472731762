const mapScript = document.createElement('script');
import { MarkerClusterer } from '@googlemaps/markerclusterer';

mapScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDD3HeYRNYPahrLeUkpnxr-mza9bvIz4MQ&callback=initMap';
mapScript.async = true;

const globalStyles = [
	{
		featureType: 'administrative',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'landscape',
		elementType: 'all',
		stylers: [
			{
				hue: '#0066ff',
			},
			{
				saturation: 74,
			},
			{
				lightness: 100,
			},
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'landscape.man_made',
		elementType: 'all',
		stylers: [
			{
				visibility: 'on',
			},
			{
				saturation: '-50',
			},
			{
				color: '#eeeeee',
			},
		],
	},
	{
		featureType: 'landscape.man_made',
		elementType: 'geometry.fill',
		stylers: [
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'landscape.natural',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#f2f2f2',
			},
		],
	},
	{
		featureType: 'landscape.natural.landcover',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#f8f8f8',
			},
		],
	},
	{
		featureType: 'landscape.natural.terrain',
		elementType: 'geometry.fill',
		stylers: [
			{
				visibility: 'simplified',
			},
			{
				color: '#bde3c1',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'all',
		stylers: [
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'all',
		stylers: [
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off',
			},
			{
				weight: 0.6,
			},
			{
				saturation: -85,
			},
			{
				lightness: 61,
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry',
		stylers: [
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'road.arterial',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'road.local',
		elementType: 'all',
		stylers: [
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'transit',
		elementType: 'all',
		stylers: [
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'all',
		stylers: [
			{
				visibility: 'simplified',
			},
			{
				color: '#5f94ff',
			},
			{
				lightness: 26,
			},
			{
				gamma: 5.86,
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#b8e7f2',
			},
		],
	},
];

export const globalMaps = () => {
	const mapContainer = document.querySelector('.global-map');

	if (!mapContainer) return;

	const getZoom = mapContainer.getAttribute('data-zoom') * 1;
	const getCoordY = mapContainer.getAttribute('data-coord-y') * 1;
	const getCoordX = mapContainer.getAttribute('data-coord-x') * 1;
	const icon = mapContainer.getAttribute('data-marker');

	// Create the map.
	window.initMap = function () {
		const map = new google.maps.Map(mapContainer, {
			zoom: getZoom,
			center: {
				lat: getCoordX,
				lng: getCoordY,
			},
			styles: globalStyles,
		});

		// Create marker
		new google.maps.Marker({
			position: new google.maps.LatLng(getCoordX, getCoordY),
			map,
			title: 'Mediteran',
			icon: {
				url: icon,
				scaledSize: window.isMobile ? new google.maps.Size(30, 30) : new google.maps.Size(35, 35),
			},
		});

		// Add class to marker
		map.addListener('idle', () => {
			setTimeout(() => {
				document.querySelector(`img[src='${icon}']`)?.closest('div').classList.add('global-map-styles-marker');
			}, 100);
		});
	};

	document.head.appendChild(mapScript);
};

export const locationsMap = () => {
	const locationsMapContainer = document.querySelector('.locations__map-box');

	if (!locationsMapContainer) return;
	window.initMap = function () {
		const mainLatMap = locationsMapContainer.dataset.lat * 1;
		const mainLngMap = locationsMapContainer.dataset.lng * 1;

		const getLocations = document.querySelectorAll('.location');
		const allLocations = [];
		let marker;

		const infowindow = new google.maps.InfoWindow({
			maxWidth: 350,
			pixelOffset: new google.maps.Size(-10, -25),
		});

		const locationsGoogleMap = new google.maps.Map(locationsMapContainer, {
			zoom: 7,
			center: {
				lat: mainLatMap,
				lng: mainLngMap,
			},
			styles: globalStyles,
		});

		function addMarker(location) {
			marker = new google.maps.Marker({
				position: new google.maps.LatLng(location.dataset.lat * 1, location.dataset.lng * 1),
				locationsGoogleMap,
				icon: {
					scaledSize: new google.maps.Size(20, 32),
					url: '',
				},
				optimized: false,
				title: location.dataset.filters,
			});
			marker.setMap(locationsGoogleMap);
		}

		function addTooltipInfo(location) {
			return function () {
				let tooltip = `<div class="locations__tooltip">
				<img class="locations__tooltip-img" src="${location.querySelector('.location__img').getAttribute('src')}" />
					<div class="locations__tooltip-box">
						<p class="locations__tooltip-name">${location.querySelector('.location__name').textContent}</p>
						<div class="locations__tooltip-region">${location.querySelector('.location__region span').textContent}</div>
						<a href="${location
							.querySelector('.location__wrapper')
							.getAttribute('href')}" class="btn btn__outline btn__outline--black btn--small">${
					location.querySelector('.location__btn').textContent
				}</a>
					</div>

				</div>`;
				infowindow.setContent(tooltip);
				infowindow.open(locationsGoogleMap);
				infowindow.setPosition(new google.maps.LatLng(location.dataset.lat * 1, location.dataset.lng * 1));
			};
		}

		function addClassesToMarker() {
			setTimeout(() => {
				const allMarkers = document.querySelectorAll(`div[title]`);
				allMarkers.forEach((marker) => {
					if (!marker.title.includes('location__marker')) return;
					marker.classList.add('location__marker-box');
					const markerTerms = marker.title.split(' ');
					const markerImage = marker.querySelector('img');
					markerImage.classList.add(markerTerms[0]);
					markerImage.classList.add(markerTerms[1]);
					markerImage.classList.add(markerTerms[2]);
					markerImage.classList.add(markerTerms[3]);
				});
			}, 1000);
		}

		function createMarkers() {
			getLocations.forEach((location) => {
				let locationName = location.querySelector('.location__name').textContent;

				allLocations.push([locationName, location.dataset.lat * 1, location.dataset.lng * 1]);

				addMarker(location);

				let markerTooltip = addTooltipInfo(location);
				google.maps.event.addListener(marker, 'click', markerTooltip);
			});
		}

		createMarkers();
		addClassesToMarker();
	};

	document.head.appendChild(mapScript);
};
