import SwiperCore, { Pagination, Autoplay, EffectCreative, EffectFade, Thumbs } from 'swiper/core';

SwiperCore.use([Pagination, Autoplay, EffectCreative, EffectFade, Thumbs]);
const mq = window.matchMedia('(min-width: 992px)');
(function () {
	const homepageContainer = document.querySelector('.hp-slider__wrapper');
	const nav = document.querySelector('.nav');

	if (!homepageContainer) return;

	const iconsSwiper = new SwiperCore('.hp-slider__icons', {
		slidesPerView: 1,
		speed: 1000,
		effect: 'fade',
		fadeEffect: {
			crossFade: true,
		},
	});

	if (mq.matches) {
		homepageContainer.style.height = `calc(100vh - ${nav.clientHeight}px - 20px)`;

		new SwiperCore(homepageContainer, {
			speed: 1200,
			spaceBetween: 40,
			centeredSlides: true,
			preventInteractionOnTransition: true,
			autoplay: {
				delay: 5000,
			},
			effect: 'creative',
			slidesPerView: 1.1,
			creativeEffect: {
				limitProgress: 4,
				prev: {
					translate: ['-102%', 0, 0],
					origin: `right top`,
					scale: 0.9,
				},
				next: {
					translate: ['102%', 0, 0],
					scale: 0.85,
					origin: `left top`,
				},
			},
			thumbs: {
				swiper: iconsSwiper,
			},
			loop: true,
			pagination: {
				el: '.hp-slider__pagination',
				clickable: true,
				renderBullet: function (index, className) {
					const getTitle = document
						.querySelector(`[data-slide-location-${index + 1}]`)
						.getAttribute(`data-slide-location-${index + 1}`);
					return `<div class="${className}"><span class="swiper-pagination__location">${getTitle}</span></div>`;
				},
			},
		});
	} else {
		homepageContainer.style.height = `calc(100vh - ${nav.clientHeight}px - 4rem)`;

		new SwiperCore(homepageContainer, {
			speed: 1200,
			centeredSlides: true,
			preventInteractionOnTransition: true,
			autoplay: {
				delay: 8000,
				disableOnInteraction: true,
			},
			effect: 'fade',
			slidesPerView: 1,
			loop: true,
			pagination: {
				el: '.hp-slider__pagination',
				clickable: true,
			},
			thumbs: {
				swiper: iconsSwiper,
			},
		});
	}
})();
