import SwiperCore, { Autoplay } from 'swiper/core';

SwiperCore.use([Autoplay]);

(function () {
	const mediteranInNumbers = document.querySelector('.mediteran-numbers__list');

	new SwiperCore(mediteranInNumbers, {
		speed: 1200,
		slidesPerView: 1.35,
		spaceBetween: 24,
		centeredSlides: true,
		initialSlide: 1,
		breakpoints: {
			414: {
				slidesPerView: 2,
				centeredSlides: false,
			},
			576: {
				slidesPerView: 3,
				centeredSlides: false,
			},
			1024: {
				slidesPerView: 4,
				centeredSlides: false,
			},
		},
	});
})();
