export const initMessenger = () => {
	const chatbox = document.getElementById('fb-customer-chat');
	chatbox.setAttribute('page_id', '1076545875865058');
	chatbox.setAttribute('attribution', 'biz_inbox');

	window.fbAsyncInit = function () {
		FB.init({
			xfbml: true,
			version: 'v15.0',
		});

		FB.Event.subscribe('xfbml.render', function () {
			document.querySelector('.fb_helper').classList.remove('fb_loading');
		});
	};

	(function (d, s, id) {
		var js,
			fjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) return;
		js = d.createElement(s);
		js.id = id;
		js.src = 'https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js';
		fjs.parentNode.insertBefore(js, fjs);
	})(document, 'script', 'facebook-jssdk');

	const consultantBtn = document.querySelectorAll('.messenger-chat-consultant');

	if (consultantBtn.length < 1) return;
	consultantBtn.forEach((btn) => {
		btn.addEventListener('click', (e) => {
			e.target.classList.add('btn__loading--active');
			const checkIfFBIsLoaded = setInterval(function () {
				if (!document.querySelector('.fb_helper').classList.contains('fb_loading')) {
					clearInterval(checkIfFBIsLoaded);
					e.target.classList.remove('btn__loading--active');
					document.querySelector('.fb_reset').style.opacity = 1;
				}
			}, 1000);
		});
	});
};
