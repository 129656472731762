import Instafeed from 'instafeed.js';

export const initInstagram = () => {
	const instaFeedContainer = document.querySelector('.instagram');
	if (!instaFeedContainer) return;

	const feed = new Instafeed({
		accessToken: siteGlobalVariables.instaCode,
		limit: 4,
		error: () => {
			instaFeedContainer.remove();
		},
	});

	feed.run();
};
