import Cookies from 'js-cookie';

export const initCookies = () => {
	const cookieBox = document.querySelector('.cookies');
	const cookieButton = document.querySelector('.btn__cookies');

	if (!Cookies.get('cookie-box-mediteran')) {
		cookieBox.classList.remove('cookies--hide');
		cookieButton.onclick = () => {
			Cookies.set('cookie-box-mediteran', true, {
				expires: 7,
			});
			cookieBox.classList.add('cookies--hide');
		};
	}
};

export const pushParamsToSiteUrl = () => {
	const allParams = ['location', 'check-in', 'check-out', 'guests'];
	const generateParamsFromCookies = [];
	const historyState = {};

	function getParamsFromCookies(name) {
		const cookieValue = Cookies.get(`mediteran-searchbar-${name}`);
		if (cookieValue) {
			generateParamsFromCookies.push(`${name}=${cookieValue}`);
			historyState[`${name}`] = cookieValue;
		}
	}

	function setCookieByHistoryState(name) {
		const urlParams = new URLSearchParams(window.location.search);
		const paramValue = urlParams.get(name);

		if (paramValue) {
			Cookies.set(`mediteran-searchbar-${name}`, paramValue, { expires: 7 });

			getParamsFromCookies(name);
		}
	}

	allParams.forEach((param) => {
		// Check params exist in URL
		if (new URL(window.location).search) {
			setCookieByHistoryState(param);
		} else {
			getParamsFromCookies(param);
		}
		// setCookieByHistoryState(param);
		if (allParams[allParams.length - 1] === param) {
			const nextURL = `${window.location.origin}${window.location.pathname}?${generateParamsFromCookies.join('&')}`;

			window.history.pushState(historyState, null, nextURL);
			window.history.replaceState(historyState, null, nextURL);
		}
	});
};
