import SwiperCore, { Pagination, Autoplay, EffectFade, EffectCreative, Thumbs } from 'swiper/core';

SwiperCore.use([Pagination, Autoplay, EffectFade, Thumbs, EffectCreative]);
const mq = window.matchMedia('(min-width: 992px)');

export const eventsSlider = () => {
	const eventsContainer = document.querySelector('.events-slider');
	if (!eventsContainer || mq.matches) return;

	new SwiperCore(eventsContainer, {
		speed: 1200,
		centeredSlides: true,
		slidesPerView: 1.25,
		spaceBetween: 20,
		initialSlide: 1,
		loop: true,

		breakpoints: {
			576: {
				slidesPerView: 2,
			},
		},
	});
};

export const locationHeaderSlider = () => {
	const locationHeaderContainer = document.querySelector('.location-header__gallery');
	if (!locationHeaderContainer || mq.matches) return;

	new SwiperCore(locationHeaderContainer, {
		speed: 1200,
		centeredSlides: true,
		spaceBetween: 20,
		initialSlide: 1.2,
		loop: true,
		effect: 'creative',
		slidesPerView: 1.2,
		creativeEffect: {
			limitProgress: 4,
			prev: {
				translate: ['-105%', 0, 0],
				origin: `right center`,
				scale: 0.9,
			},
			next: {
				translate: ['105%', 0, 0],
				scale: 0.85,
				origin: `left center`,
			},
		},
		breakpoints: {
			768: {
				slidesPerView: 2,
			},
		},
	});
};
