import DateRangePicker from 'vanillajs-datepicker/DateRangePicker';
import { Datepicker } from 'vanillajs-datepicker';
import pl from '../../../node_modules/vanillajs-datepicker/js/i18n/locales/pl';
import de from '../../../node_modules/vanillajs-datepicker/js/i18n/locales/de';

Object.assign(Datepicker.locales, pl);
Object.assign(Datepicker.locales, de);

import SlimSelect from 'slim-select';
import Cookies from 'js-cookie';
require('regenerator-runtime/runtime');

const mediaQuery = window.matchMedia('(max-width: 992px)'),
	mobileHelper = document.querySelector('.mobile-helper'),
	historyState = {},
	generateParams = [];

function pushAndCheckParams(paramName, paramValue) {
	const match = generateParams.find((param) => {
		if (param.includes(paramName)) {
			return param;
		}
	});

	if (!match) {
		generateParams.push(`${paramName}=${paramValue}`);
	} else {
		const indexParam = generateParams.indexOf(match);
		generateParams[indexParam] = `${paramName}=${paramValue}`;
	}
}

function checkUrlContainAnyParams() {
	const paramsString = window.location.search;
	if (!paramsString) return;
	const searchParams = new URLSearchParams(paramsString);

	for (let urlParam of searchParams) {
		pushAndCheckParams(urlParam[0], urlParam[1]);
	}
}

function setDatesToRangePicker(rangePicker) {
	const paramsString = window.location.search;
	if (!paramsString) return;
	const searchParams = new URLSearchParams(paramsString);

	const checkInDate = new Date(searchParams.get('check-in'));
	const checkOutDate = new Date(searchParams.get('check-out'));
	rangePicker.setDates(checkInDate, checkOutDate);
}
const initDatePickers = () => {
	const dateRangePicker = document.getElementById('searchbar-range-picker');
	const today = siteGlobalVariables.serverTime;

	const rangePicker = new DateRangePicker(dateRangePicker, {
		format: 'dd M yyyy',
		minDate: today,
		maxView: 1,
		weekStart: 1,
		language: siteGlobalVariables.currentLang,
		autohide: true,
	});

	setDatesToRangePicker(rangePicker);

	const allInitializedDatePickers = dateRangePicker.querySelectorAll('.searchbar__date-picker');
	allInitializedDatePickers.forEach((picker, index) => {
		const childBtn = picker.querySelector('.searchbar__date-picker-btn'),
			closeBtn = picker.querySelector('.searchbar__date-picker-close-btn'),
			childCheckText = picker.querySelector('.searchbar__date-picker-mobile-txt'),
			pickerElOfInstance = rangePicker.datepickers[index],
			pickerInput = picker.querySelector('input');

		picker.querySelector('.datepicker-footer .datepicker-controls').appendChild(childBtn);
		picker.querySelector('.datepicker-header').appendChild(childCheckText);
		picker.querySelector('.datepicker-header').appendChild(closeBtn);

		pickerInput.addEventListener('changeDate', function (e, details) {
			if (pickerInput.classList.contains('datepicker-input-min')) {
				const formatDate = pickerElOfInstance.getDate('yyyy-mm-dd');

				setCookies('check-in', formatDate);
				pushAndCheckParams('check-in', formatDate);
				historyState['check-in'] = formatDate;
				changeUrlParams(generateParams, historyState);

				rangePicker.datepickers[1].show();

				// Working only on the single location page
				const startDateTooltipInfo = document.querySelector('.searchbar__start-date-tooltip');
				if (startDateTooltipInfo.classList.contains('is-active')) startDateTooltipInfo.classList.remove('is-active');
			} else {
				// Add one day to end date if start date and end date are the same
				const getFormatStartDate = rangePicker.getDates('dd/mm/yyyy')[0];
				const getFormatEndDate = rangePicker.getDates('dd/mm/yyyy')[1];

				if (getFormatStartDate == getFormatEndDate) {
					const addOneDayToEndDate = pickerElOfInstance.getDate('dd') * 1 + 1;
					const combineDayToMonthAndYear = addOneDayToEndDate + pickerElOfInstance.getDate('/mm/yyyy');

					rangePicker.setDates(getFormatStartDate, combineDayToMonthAndYear);
				}

				const formatDate = pickerElOfInstance.getDate('yyyy-mm-dd');

				setCookies('check-out', formatDate);
				pushAndCheckParams('check-out', formatDate);
				historyState['check-out'] = formatDate;
				changeUrlParams(generateParams, historyState);

				// Working only on the single location page
				const searchBtnTooltipInfo = document.querySelector('.searchbar__btn-tooltip');
				searchBtnTooltipInfo.classList.add('is-active');
			}
		});

		pickerInput.addEventListener('show', (e) => {
			const parentBox = e.target.closest('.searchbar__date-picker');
			parentBox.classList.add('is-active');
		});

		pickerInput.addEventListener('hide', (e) => {
			const parentBox = e.target.closest('.searchbar__date-picker');
			parentBox.classList.remove('is-active');
		});

		childBtn.addEventListener('click', () => {
			rangePicker.datepickers[index].hide();

			// Only works on the location page
			if (document.body.classList.contains('single-our_locations')) {
				document.querySelector('.nav__search').click();
			}
		});

		closeBtn.addEventListener('click', () => {
			rangePicker.datepickers[index].hide();
		});
	});
};

const searchbarGuests = () => {
	const searchbarContainer = document.querySelector('.searchbar'),
		searchbarGuestsWrapper = document.querySelector('.searchbar__guests'),
		searchbarGuestsMinus = searchbarGuestsWrapper.querySelector('.searchbar__guests-minus'),
		searchbarGuestsPlus = searchbarGuestsWrapper.querySelector('.searchbar__guests-plus'),
		searchbarGuestsInput = searchbarGuestsWrapper.querySelector('input'),
		searchbarGuestsMax = searchbarGuestsInput.getAttribute('max'),
		searchbarGuestsCloseBtn = searchbarGuestsWrapper.querySelector('.searchbar__guests-dropdown-close-btn');

	// Toggle dropdown
	searchbarGuestsInput.addEventListener('focusin', () => {
		searchbarGuestsWrapper.classList.add('is-active');

		if (isNaN(parseInt(searchbarGuestsInput.value))) {
			searchbarGuestsInput.value = 1;
			pushAndCheckParams('guests', 1);
			historyState['guests'] = 1;
			changeUrlParams(generateParams, historyState);

			setCookies('guests', 1);
		}
	});

	document.addEventListener('mousedown', (e) => {
		if (!e.target.closest('.searchbar__guests')) {
			searchbarGuestsWrapper.classList.remove('is-active');
		}
	});

	searchbarContainer.addEventListener('focusin', (e) => {
		if (!e.target.closest('.searchbar__guests')) {
			searchbarGuestsWrapper.classList.remove('is-active');
		}
	});

	searchbarGuestsCloseBtn.addEventListener('click', () => {
		searchbarGuestsWrapper.classList.remove('is-active');
	});

	searchbarGuestsMinus.addEventListener('click', (e) => {
		const searchbarGuestsNumber = searchbarGuestsWrapper.querySelector('.searchbar__guests-dropdown-number');
		let guestsCount = parseInt(searchbarGuestsNumber.textContent) - 1;
		guestsCount = guestsCount < 1 ? 1 : guestsCount;
		searchbarGuestsNumber.textContent = guestsCount;
		searchbarGuestsInput.value = guestsCount;

		pushAndCheckParams('guests', guestsCount);
		historyState['guests'] = guestsCount;
		changeUrlParams(generateParams, historyState);

		setCookies('guests', guestsCount);
	});

	searchbarGuestsPlus.addEventListener('click', () => {
		const searchbarGuestsNumber = searchbarGuestsWrapper.querySelector('.searchbar__guests-dropdown-number');
		let guestsCount = parseInt(searchbarGuestsNumber.textContent) + 1;
		if (guestsCount <= searchbarGuestsMax) {
			searchbarGuestsNumber.textContent = guestsCount;
			searchbarGuestsInput.value = guestsCount;

			pushAndCheckParams('guests', guestsCount);
			historyState['guests'] = guestsCount;
			changeUrlParams(generateParams, historyState);

			setCookies('guests', guestsCount);
		}
	});
};

const toggleSearchbarOnMobile = () => {
	if (!mediaQuery.matches) return;

	const searchbarButton = document.querySelector('.nav__search'),
		searchbar = document.querySelector('.searchbar'),
		searchbarCloseBtn = searchbar.querySelector('.searchbar__close');

	function toggleSearchbarClasses() {
		searchbar.classList.toggle('is-active');
		searchbarButton.classList.toggle('is-active');
		mobileHelper.classList.toggle('is-active');
	}

	searchbarButton.addEventListener('click', (e) => {
		toggleSearchbarClasses();
	});

	searchbarCloseBtn.addEventListener('click', (e) => {
		toggleSearchbarClasses();
	});

	// Only homepage slider
	const searchbarButtonSlider = document.querySelector('.hp-slider__search');
	if (!searchbarButtonSlider) return;

	searchbarButtonSlider.addEventListener('click', (e) => {
		toggleSearchbarClasses();
	});
};

const ajaxLocations = () => {
	const currentPageLang = siteGlobalVariables.currentLang,
		searchbarLocationsSelect = document.querySelector('#ajax-locations-input'),
		searchbarLocationsTopInfo = document.querySelector('.searchbar__location-top-info'),
		searchingText = searchbarLocationsSelect.getAttribute('data-searchbar-locations-searching'),
		searchPlaceholder = searchbarLocationsSelect.getAttribute('data-searchbar-locations-placeholder'),
		searchText = searchbarLocationsSelect.getAttribute('data-searchbar-locations-results'),
		searchResultsInfo = searchbarLocationsSelect.getAttribute('data-searchbar-locations-info');
	let responseLocations;

	// Get All locations from WP
	fetch(`${siteGlobalVariables.homeUrl}/wp-json/wp/v2/locations`)
		.then(function (response) {
			return response.json();
		})
		.then(function (json) {
			responseLocations = json;
		})
		.catch(function (error) {
			console.log(error);
		});

	// Generate selctboxes
	new SlimSelect({
		select: searchbarLocationsSelect,
		searchingText,
		placeholder: searchPlaceholder,
		searchPlaceholder,
		searchText,
		searchFilter: (option, search) => {
			const optionText = option.text.toLowerCase();
			const locationSubstring = optionText.includes(search.toLowerCase());
			const hasParentLocation = option.data.link?.includes(search.toLowerCase());

			if (locationSubstring || hasParentLocation) return true;
		},
		beforeOpen: () => {
			searchbarLocationsTopInfo.classList.add('is-active');
			const searchbarLocationDisabledOption = document.querySelector('.searchbar__location .ss-option.ss-disabled');

			if (searchbarLocationDisabledOption.innerText == searchText) {
				searchbarLocationDisabledOption.innerText = searchResultsInfo;
			}
		},
		beforeClose: () => {
			searchbarLocationsTopInfo.classList.remove('is-active');
		},
		onChange: (info) => {
			setCookies('location', info.data.id);
			pushAndCheckParams('location', info.data.id);
			historyState['location'] = info.data.id;

			changeUrlParams(generateParams, historyState);
		},
		ajax: function (search, callback) {
			if (search.length < 3) {
				callback(searchResultsInfo);
				return;
			}
			if (responseLocations) {
				let data = [];
				responseLocations.forEach((location) => {
					if (location.lang !== currentPageLang) return;
					data.push({
						text: location.name,
						data: {
							link: location.link,
							id: location.id,
						},
					});
				});

				callback(data);
			}
		},
	});
};

function setCookies(name, value, days = 7) {
	Cookies.set(`mediteran-searchbar-${name}`, value, { expires: days });
}

function changeUrlParams(params, historyState) {
	const nextURL = `${window.location.origin}${window.location.pathname}?${params.join('&')}`;
	window.history.pushState(historyState, null, nextURL);
	window.history.replaceState(historyState, null, nextURL);
}

export const initSearchbar = () => {
	checkUrlContainAnyParams();
	initDatePickers();
	toggleSearchbarOnMobile();
	ajaxLocations();
	searchbarGuests();
};
