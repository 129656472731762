import SwiperCore, { Autoplay } from 'swiper/core';

SwiperCore.use([Autoplay]);

(function () {
	const aboutUsGallery = document.querySelector('.about-us__gallery');

	new SwiperCore(aboutUsGallery, {
		speed: 2000,
		initialSlide: 0,
		slidesPerView: 1.05,
		autoplay: {
			delay: 8000,
		},
		spaceBetween: 24,
		centeredSlides: false,
		loop: true,
		breakpoints: {
			414: {
				slidesPerView: 1.3,
			},
			1024: {
				slidesPerView: 1.7,
			},
			1536: {
				slidesPerView: 1.8,
			},
		},
	});
})();
