import SlimSelect from 'slim-select';
import tippy from 'tippy.js';

export const initWoocommerceInputs = () => {
	const allInputs = document.querySelectorAll('.woocommerce-Input, .woocommerce-address-fields .input-text');

	if (!allInputs) return;

	// Labels
	function focusLabels(item) {
		item.addEventListener('focusin', (e) => {
			e.target.closest('.woocommerce-form-row, .form-row').classList.add('is-focus');
		});

		item.addEventListener('change', (e) => {
			e.target.closest('.woocommerce-form-row, .form-row').classList.add('is-focus');
		});

		item.addEventListener('focusout', (e) => {
			if (e.target.value === '') {
				e.target.closest('.woocommerce-form-row, .form-row').classList.remove('is-focus');
			}
		});

		if (item.value) {
			item.closest('.woocommerce-form-row, .form-row').classList.add('is-focus');
		}
	}

	function showMyPassword(input) {
		input.nextElementSibling.addEventListener('click', (e) => {
			if (input.type === 'password' && input.value.length > 0) {
				input.type = 'text';
				e.target.classList.add('is-active');
			} else {
				input.type = 'password';
				e.target.classList.remove('is-active');
			}
		});
	}

	allInputs.forEach((input) => {
		focusLabels(input);
		if (input.type === 'password') {
			showMyPassword(input);
		}
	});
};

export const initWoocommerceSelectboxes = () => {
	const allSelectboxes = document.querySelectorAll('.woocommerce-address-fields select');

	if (!allSelectboxes) return;
	allSelectboxes.forEach((select) => {
		new SlimSelect({
			select: select,
			showSearch: false,
		});
	});
};

export const initTicketsInfoTooltips = () => {
	tippy('.buy-ticket__variation-desc', {
		animation: 'scale',
		maxWidth: 300,
		content(reference) {
			return reference.textContent;
		},
	});
};

export const redirectLocationCheckout = () => {
	jQuery('body').on('checkout_error', function () {
		const checkoutErrorHelper = document.querySelector('.checkout-redirect');
		if (checkoutErrorHelper) {
			const lastLoaderContainer = document.querySelector('.reservation .reservation-form');
			document.querySelector('.woocommerce-NoticeGroup').style.display = 'none';
			lastLoaderContainer.classList.add('last-loader-active');
			window.location = siteGlobalVariables.locationCheckoutUrl;
		}
	});
};

export const addClassToButtonPlaceOrder = () => {
	const paymentBtn = document.querySelector('.summary__payment-btn');

	if (!paymentBtn) return;

	paymentBtn.addEventListener('click', () => {
		paymentBtn.classList.add('btn__loading--active');
	});
};
