import axios from 'axios';
import { initLightboxesAndVideos } from './lightboxes';
import Cookies from 'js-cookie';
import SlimSelect from 'slim-select';

export const renderObjectModal = (urlHash) => {
	const modalWrapper = document.querySelector('.object-modal'),
		objectsWrapper = document.querySelector('.objects-availability__list');

	if (!objectsWrapper) return;

	function closeModal(modalContainer) {
		location.hash = 'modal';
		modalContainer.classList.add('is-hide');
		modalWrapper.classList.remove('is-active');
		setTimeout(() => {
			modalContainer.remove();
		}, 400);
	}

	function openModal(productId, modalBtn) {
		let formData = new FormData();
		formData.append('action', 'render_modal');
		formData.append('product_id', productId);
		axios.post('/wp-admin/admin-ajax.php', formData).then((res) => {
			let data = res.data;
			modalWrapper.innerHTML = data;

			setTimeout(() => {
				const modalContainer = document.querySelector('.object-modal__container');
				const closeBtn = modalContainer.querySelector('.object-modal__close');

				modalWrapper.classList.add('is-active');

				if (modalBtn) {
					modalBtn.classList.remove('btn__loading--active');
				}

				initLightboxesAndVideos(true);

				closeBtn.addEventListener('click', () => {
					closeModal(modalContainer);
				});
			}, 100);
		});
	}

	objectsWrapper.addEventListener('click', (e) => {
		if (e.target.classList.contains('object-modal-btn')) {
			const btn = e.target,
				productId = btn.getAttribute('data-object-id'),
				productHash = btn.getAttribute('data-hash');

			if (productId) {
				history.pushState(null, null, productHash);
				btn.classList.add('btn__loading--active');
				openModal(productId, btn);
			}
		}
	});

	document.addEventListener('openModalByHash', () => {
		const getUrlHash = urlHash;
		if (!getUrlHash.includes('modal-') || getUrlHash.length > 15) return;
		const getProductIdFromUrlHash = getUrlHash.substring(getUrlHash.indexOf('-') + 1);
		const getButtonByHash = document.querySelector(`a[href='${getUrlHash}']`);
		openModal(getProductIdFromUrlHash, getButtonByHash);
	});
};

// Cart
export const renderProductModal = () => {
	const modalWrapper = document.querySelector('.object-modal'),
		openModalButtons = document.querySelectorAll('.reservation-product__btn');

	if (!openModalButtons) return;

	function closeModal(modalContainer) {
		modalContainer.classList.add('is-hide');
		modalWrapper.classList.remove('is-active');
		setTimeout(() => {
			modalContainer.remove();
		}, 400);
	}

	function openModal(productId, modalBtn) {
		let formData = new FormData();
		formData.append('action', 'render_modal');
		formData.append('product_id', productId);
		axios.post('/wp-admin/admin-ajax.php', formData).then((res) => {
			let data = res.data;
			modalWrapper.innerHTML = data;

			setTimeout(() => {
				const modalContainer = document.querySelector('.object-modal__container');
				const closeBtn = modalContainer.querySelector('.object-modal__close');

				modalWrapper.classList.add('is-active');
				modalBtn.classList.remove('is-active');

				initLightboxesAndVideos(true);

				closeBtn.addEventListener('click', () => {
					closeModal(modalContainer);
				});
			}, 100);
		});
	}
	openModalButtons.forEach((openModalBtn) => {
		openModalBtn.addEventListener('click', (e) => {
			e.preventDefault();
			const btn = e.target;
			const productId = btn.getAttribute('data-object-id');
			if (productId) {
				btn.classList.add('is-active');
				openModal(productId, btn);
			}
		});
	});
};

// Single location
export const renderUnitTypes = () => {
	const unitTypesAvailabilityContainer = document.querySelector('.objects-availability'),
		unitTypesList = unitTypesAvailabilityContainer.querySelector('.objects-availability__list'),
		noResults = unitTypesAvailabilityContainer.querySelector('.objects-no-results'),
		amountOfUnitTypes = unitTypesAvailabilityContainer.querySelector('.objects-availability__header-right span'),
		unitTypesLoader = document.querySelector('.objects-availability__loader'),
		changeDatesBtn = unitTypesAvailabilityContainer.querySelector('.objects-availability__change-dates'),
		checkInDate = document.querySelector('.objects-availability__dates-check-in'),
		checkOutDate = document.querySelector('.objects-availability__dates-check-out');

	if (!changeDatesBtn) return;

	function openCalendar() {
		document.querySelector('.nav__search').click();
		document.querySelector('.datepicker-input-min').click();
		document.querySelector('.datepicker-input-min').focus();
	}

	// AJAX add to cart
	function addToCartUnitType() {
		const allAddToCartBtns = document.querySelectorAll('.single_add_to_cart_button'),
			noticesWrapper = document.querySelector('.unit-types__notices'),
			noticeProductExist = noticesWrapper.querySelector('.unit-types__notices--exists-in-cart'),
			noticeNotAvailable = noticesWrapper.querySelector('.unit-types__notices--not-available'),
			basket = document.querySelector('.basket');

		allAddToCartBtns.forEach((btn) => {
			btn.addEventListener('click', (e) => {
				e.preventDefault();
				const productBtn = e.target;

				productBtn.classList.add('btn__loading--active');
				noticesWrapper.classList.remove('is-active');
				noticeProductExist.classList.remove('is-active');
				noticeNotAvailable.classList.remove('is-active');

				// Prepare product data
				const productQuantity = 1,
					productId = productBtn.value,
					variationId = 0;

				let formData = new FormData();
				formData.append('action', 'woo_ajax_add_to_cart');
				formData.append('product_id', productId);
				formData.append('check_in_date', preparedSearchbarParams.check_in_date);
				formData.append('check_out_date', preparedSearchbarParams.check_out_date);
				formData.append('quantity', productQuantity);
				formData.append('variation_id', variationId);

				axios.post('/wp-admin/admin-ajax.php', formData).then((res) => {
					if (res.data.error) {
						if (res.data.type === 'exists-in-cart') {
							noticeProductExist.classList.add('is-active');
						} else {
							noticeNotAvailable.classList.add('is-active');
						}

						noticesWrapper.classList.add('is-active');
						productBtn.classList.remove('btn__loading--active');
					} else {
						if (basket.classList.contains('is-hide')) {
							basket.classList.remove('is-hide');
						} else {
							basket.classList.add('is-animation');
							setTimeout(() => {
								basket.classList.remove('is-animation');
							}, 600);
						}

						window.location = siteGlobalVariables.locationCartUrl;
					}
				});
			});
		});

		basket.addEventListener('click', () => {});
	}

	// Prepare data for ajax request
	let formData = new FormData();
	formData.append('action', 'render_unit_types');
	formData.append('rentlio_id', preparedSearchbarParams.id);
	formData.append('check_in_date', preparedSearchbarParams.check_in_date);
	formData.append('check_out_date', preparedSearchbarParams.check_out_date);
	formData.append('guests', preparedSearchbarParams.guests);

	// Open calendar in searchbar by button next to dates
	changeDatesBtn.addEventListener('click', () => {
		openCalendar();
	});

	// Change dates
	const searchbarDatePickersBtns = document.querySelectorAll('.searchbar__date-picker-btn ');

	searchbarDatePickersBtns.forEach((pickerBtn) => {
		pickerBtn.addEventListener('click', () => {
			const checkInDateFromCookies = Cookies.get('mediteran-searchbar-check-in');
			const checkOutDateFromCookies = Cookies.get('mediteran-searchbar-check-out');

			preparedSearchbarParams.check_in_date = checkInDateFromCookies;
			preparedSearchbarParams.check_out_date = checkOutDateFromCookies;

			formData.set('check_in_date', checkInDateFromCookies);
			formData.set('check_out_date', checkOutDateFromCookies);
			formData.set('changed_date', true);

			checkInDate.innerText = new Date(checkInDateFromCookies).toLocaleDateString('en-GB').replaceAll('/', '.');
			checkOutDate.innerText = new Date(checkOutDateFromCookies).toLocaleDateString('en-GB').replaceAll('/', '.');
			getRenderedUnitTypes();
		});
	});

	function getRenderedUnitTypes(firstRender) {
		noResults.classList.add('is-disable');
		unitTypesLoader.classList.add('is-loading');
		unitTypesAvailabilityContainer.classList.remove('is-loaded');
		unitTypesList.innerHTML = '';

		axios
			.post('/wp-admin/admin-ajax.php', formData)
			.then((res) => {
				let data = res.data;

				unitTypesLoader.classList.remove('is-loading');

				if (data == false) {
					noResults.classList.remove('is-disable');
					amountOfUnitTypes.innerText = 0;
				} else {
					setTimeout(() => {
						unitTypesList.innerHTML = data;
						addToCartUnitType();
						// Update amount of unit types
						const allSingleObjects = document.querySelectorAll('.single-object');
						if (allSingleObjects.length > 0) {
							amountOfUnitTypes.innerText = allSingleObjects.length;
						} else {
							amountOfUnitTypes.innerText = 0;
						}
					}, 100);

					setTimeout(() => {
						unitTypesAvailabilityContainer.classList.add('is-loaded');
						if (firstRender) {
							document.dispatchEvent(new Event('openModalByHash'));
						}
					}, 300);
				}
			})
			.catch(() => {
				unitTypesLoader.classList.remove('is-loading');
				noResults.classList.remove('is-disable');
				amountOfUnitTypes.innerText = 0;
			});
	}
	getRenderedUnitTypes(true);
};

// Update total price - cart page
export const updateTotalPrice = () => {
	const allSelectsInsideProducts = document.querySelectorAll('.reservation-details .global-select'),
		reservationDetails = document.querySelector('.reservation-details'),
		reservationDetailsWrapper = document.querySelector('.reservation-details__dynamic'),
		allCheckboxesInsideProducts = document.querySelectorAll(
			'.reservation-details .reservation-product__service-checkbox'
		);

	// Selects
	if (allSelectsInsideProducts.length > 0) {
		function recalculteByReservationType(selectedValue, productId) {
			// Prepare data for ajax request
			let formData = new FormData();
			formData.append('action', 'reservation_type');
			formData.append('type', selectedValue);
			formData.append('product_id', productId);

			axios
				.post('/wp-admin/admin-ajax.php', formData)
				.then((res) => {
					let data = res.data;
					reservationDetailsWrapper.innerHTML = data;
					renderProductModal();
					updateTotalPrice();
					couponForm();

					setTimeout(() => {
						reservationDetails.classList.remove('is-loading');
					}, 500);
				})
				.catch(() => {
					location.reload();
				});
		}

		allSelectsInsideProducts.forEach((select) => {
			const product = select.closest('.reservation-product'),
				productId = product.querySelector('.reservation-product__remove').getAttribute('data-product_id');

			new SlimSelect({
				select: select,
				showSearch: false,
				onChange: (info) => {
					reservationDetails.classList.add('is-loading');
					recalculteByReservationType(info.value, productId);
				},
			});
		});
	}

	// Checkboxes
	if (allCheckboxesInsideProducts.length > 0) {
		function recalculateByServiceType(fee_id, product_id, checked) {
			let formData = new FormData();
			formData.append('action', 'service_type');
			formData.append('fee_id', fee_id);
			formData.append('product_id', product_id);
			formData.append('checked', checked);

			axios
				.post('/wp-admin/admin-ajax.php', formData)
				.then((res) => {
					let data = res.data;
					reservationDetailsWrapper.innerHTML = data;
					renderProductModal();
					updateTotalPrice();
					couponForm();

					setTimeout(() => {
						reservationDetails.classList.remove('is-loading');
					}, 500);
				})
				.catch((e) => {
					location.reload();
				});
		}

		allCheckboxesInsideProducts.forEach((checkbox) => {
			checkbox.addEventListener('change', (e) => {
				const serviceId = e.target.getAttribute('data-service-id'),
					productId = e.target.getAttribute('data-unit-id');

				reservationDetails.classList.add('is-loading');

				recalculateByServiceType(serviceId, productId, e.target.checked);
			});
		});
	}
};

export const couponForm = () => {
	const couponFormInput = document.querySelector('.checkout_coupon #coupon_code'),
		fakeCouponInput = document.querySelector('.reservation__coupon-form input'),
		fakeCouponBtn = document.querySelector('.reservation__coupon-form-btn'),
		fakeRemoveCouponBtn = document.querySelector('.woocommerce-remove-coupon');

	jQuery(document).ready(function () {
		jQuery(document.body).on('removed_coupon_in_checkout', function () {
			location.reload();
		});

		jQuery(document.body).on('applied_coupon_in_checkout', function () {
			if (!siteGlobalVariables.couponError) {
				location.reload();
			} else {
				fakeCouponBtn.classList.remove('btn__loading--active');
			}
			siteGlobalVariables.couponError = false;
		});
	});

	if (couponFormInput) {
		fakeCouponInput.addEventListener('input', (e) => {
			couponFormInput.value = e.target.value;
		});

		fakeCouponBtn.addEventListener('click', (e) => {
			const couponFormBtn = document.querySelector('.checkout_coupon.woocommerce-form-coupon button');
			fakeCouponBtn.classList.add('btn__loading--active');
			couponFormBtn.click();
		});
	}
	if (fakeRemoveCouponBtn) {
		fakeRemoveCouponBtn.addEventListener('click', () => {
			fakeRemoveCouponBtn.classList.add('btn__loading--active');
		});
	}
};

// Update total price - event checkout
export const updateTotalPriceEvent = () => {
	const allButtons = document.querySelectorAll('.buy-ticket__variation-btn'),
		orderReview = document.querySelector('.buy-ticket__review'),
		eventId = orderReview.getAttribute('data-event_id'),
		checkoutPrice = document.querySelector('.buy-ticket__total-price span'),
		eventDetails = document.querySelector('.buy-ticket__details');

	function recalculteByTicketType(productId, ticketId, btnType, btn) {
		// Prepare data for ajax request
		let formData = new FormData();
		formData.append('action', 'ticket_type');
		formData.append('product_id', productId);
		formData.append('type', ticketId);
		formData.append('btn_type', btnType);

		axios
			.post('/wp-admin/admin-ajax.php', formData)
			.then((res) => {
				let data = res.data;

				data.tickets.forEach((ticket) => {
					const ticketRow = document.querySelector(`[data-ticket="${ticket.unique_id}"]`);
					ticketRow.querySelector('.buy-ticket__variation-number').innerHTML = ticket.amount;
					ticketRow
						.querySelector('.buy-ticket__variation-btn-minus')
						.classList.toggle('is-disable', ticket.amount == 0);
				});
				checkoutPrice.innerHTML = data.total;

				// Toggle plus btns
				orderReview.classList.toggle('plus-btns--disable', data.max_tickets ?? false);

				// Toggle minus btns
				orderReview.classList.toggle('minus-btns--disable', data.min_tickets ?? false);

				eventDetails.classList.remove('is-loading');
			})
			.catch(() => {
				location.reload();
			});
	}

	allButtons.forEach((btn) => {
		btn.addEventListener('click', (e) => {
			e.preventDefault();
			const ticketId = btn.closest('.buy-ticket__variation').getAttribute('data-ticket'),
				btnType = btn.classList.contains('buy-ticket__variation-btn-minus') ? 'minus' : 'plus';
			if (ticketId) {
				eventDetails.classList.add('is-loading');
				recalculteByTicketType(eventId, ticketId, btnType, btn);
			}
		});
	});
};

// Render rating modal
export const renderRatingModal = () => {
	const modalWrapper = document.querySelector('.rating-modal'),
		ordersWrapper = document.querySelector('.account-orders__archived-list');

	if (!ordersWrapper) return;

	function sendOpinion(modalForm) {
		const sendOpinionBtn = modalForm.querySelector('.rating-modal__send'),
			sendOpinionDesc = modalForm.querySelector('textarea').value,
			sendNumberOfStars = modalForm.querySelector('input[name="rating"]:checked').value * 1;

		let opinionFormData = new FormData();
		opinionFormData.append('action', 'rating_order');
		opinionFormData.append('order_id', sendOpinionBtn.getAttribute('data-id'));
		opinionFormData.append('rating_desc', sendOpinionDesc);
		opinionFormData.append('stars', sendNumberOfStars);

		axios.post('/wp-admin/admin-ajax.php', opinionFormData).then((res) => {
			setTimeout(() => {
				location.reload();
			}, 100);
		});
	}

	function closeModal(modalContainer) {
		modalContainer.classList.add('is-hide');
		modalWrapper.classList.remove('is-active');
		setTimeout(() => {
			modalContainer.remove();
		}, 400);
	}
	function openModal(orderId, modalBtn) {
		let formData = new FormData();
		formData.append('action', 'render_rating_modal');
		formData.append('order_id', orderId);
		axios.post('/wp-admin/admin-ajax.php', formData).then((res) => {
			let data = res.data;
			modalWrapper.innerHTML = data;

			setTimeout(() => {
				const modalContainer = document.querySelector('.rating-modal__container'),
					closeBtn = modalContainer.querySelector('.rating-modal__close'),
					sendOpinionForm = modalContainer.querySelector('.rating-modal__form');

				modalWrapper.classList.add('is-active');

				modalBtn.classList.remove('btn__loading--active');

				closeBtn.addEventListener('click', () => {
					closeModal(modalContainer);
				});

				sendOpinionForm.addEventListener('submit', (e) => {
					e.preventDefault();
					sendOpinionForm.querySelector('.rating-modal__send').classList.add('btn__loading--active');
					sendOpinion(e.target);
				});
			}, 100);
		});
	}

	const ratingModalBtns = document.querySelectorAll('.account-order__modal-btn');

	ratingModalBtns.forEach((btn) => {
		btn.addEventListener('click', (e) => {
			const openModalBtn = e.target;
			const orderId = openModalBtn.getAttribute('data-id');
			if (orderId) {
				openModalBtn.classList.add('btn__loading--active');
				openModal(orderId, openModalBtn);
			}
		});
	});
};

// Render discount modal
export const renderDiscountModal = () => {
	const discountButtons = document.querySelectorAll('.account-order__discount-btn'),
		modalWrapper = document.querySelector('.discount-modal');

	if (discountButtons.length < 1) return;

	function closeModal(modalContainer) {
		modalContainer.classList.add('is-hide');
		modalWrapper.classList.remove('is-active');
		setTimeout(() => {
			modalContainer.remove();
		}, 400);
	}

	function copyToClipboard(btn) {
		navigator.clipboard.writeText(btn.getAttribute('data-coupon'));
		btn.innerText = btn.getAttribute('data-text');
		btn.classList.add('is-disable');
	}

	function openModal(orderId, modalBtn, coupon = '') {
		let formData = new FormData();
		formData.append('action', 'render_discount_modal');
		formData.append('order_id', orderId);
		formData.append('order_coupon', coupon);

		axios.post('/wp-admin/admin-ajax.php', formData).then((res) => {
			let data = res.data;
			modalWrapper.innerHTML = data;

			setTimeout(() => {
				const modalContainer = document.querySelector('.discount-modal__container'),
					closeBtn = modalContainer.querySelector('.discount-modal__close'),
					copyToClipboardBtn = modalContainer.querySelector('.discount-modal__coupon-btn');

				modalWrapper.classList.add('is-active');
				modalBtn.classList.remove('btn__loading--active');

				closeBtn.addEventListener('click', () => {
					closeModal(modalContainer);
				});

				copyToClipboardBtn.addEventListener('click', () => {
					copyToClipboard(copyToClipboardBtn);
				});
			}, 100);
		});
	}

	discountButtons.forEach((btn) => {
		btn.addEventListener('click', (e) => {
			const activeBtn = e.target,
				orderId = activeBtn.getAttribute('data-order-id'),
				coupon = activeBtn.getAttribute('data-coupon-name');

			activeBtn.classList.add('btn__loading--active');

			if (coupon) {
				openModal(orderId, activeBtn, coupon);
			} else {
				activeBtn.setAttribute('data-coupon-name', 'generated');
				openModal(orderId, activeBtn);
			}
		});
	});
};

export const saveBillingInputsToSession = () => {
	const allInputs = document.querySelectorAll('.woocommerce-Input, .woocommerce-address-fields .input-text');

	if (!allInputs) return;
	let formData = new FormData();
	formData.append('action', 'update_billing_inputs');

	// Labels
	function focusLabels(item) {
		item.addEventListener('focusout', (e) => {
			const activeInput = e.target,
				inputName = activeInput.getAttribute('id'),
				inputValue = activeInput.value;

			if (inputValue.length < 1) return;
			formData.append('input_name', inputName);
			formData.append('value', inputValue);

			axios.post('/wp-admin/admin-ajax.php', formData).then((res) => {});
		});
	}

	allInputs.forEach((input) => {
		focusLabels(input);
	});
};

export const saveMailchimpCheckboxToSession = () => {
	const mailchimpCheckbox = document.querySelector('.mc4wp-checkbox input');

	if (!mailchimpCheckbox) return;

	let formData = new FormData();
	formData.append('action', 'save_mailchimp_checkbox');

	mailchimpCheckbox.addEventListener('change', (e) => {
		formData.append('checkbox_state', e.target.checked ? 1 : 0);
		axios.post('/wp-admin/admin-ajax.php', formData).then((res) => {});
	});
};
