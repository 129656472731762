import mixitup from 'mixitup';
import SlimSelect from 'slim-select';
import mixitupMultifilter from '../vendor/mixitup-multifilter.min';
mixitup.use(mixitupMultifilter);

export const filterWithMixItUp = (container, delay = 'false') => {
	const mixContainer = document.querySelector(`.${container}__container .${container}__list`);
	if (!mixContainer) return;

	const mixFailInfo = document.querySelector('.mix-fail');
	const sectionSelectboxes = document.querySelectorAll(`.filter-selectboxes .global-select`);
	const sectionSelectbox = document.querySelector(`#${container}-select`);
	const locationsMap = document.querySelector('.locations__map');
	const amountOfLocations = document.querySelector('.locations__heading-txt-number');

	const filtermixer = mixitup(mixContainer, {
		load: {
			filter: 'all',
			sort: 'price-per-night:asc',
		},
		multifilter: {
			enable: true,
		},
		animation: {
			duration: 625,
			nudge: false,
			reverseOut: false,
			effects: 'fade translateY(20%)',
		},
		callbacks: {
			onMixFail: function (state) {
				if (mixFailInfo) {
					mixFailInfo.classList.add('is-active');
				}
			},

			onMixStart: function (state, futureState) {
				if (!futureState.hasFailed && mixFailInfo) {
					mixFailInfo.classList.remove('is-active');
				}

				// It works only on the our-locations page
				const mapMarkerTooltip = document.querySelector('.gm-style-iw.gm-style-iw-c');
				if (mapMarkerTooltip) {
					mapMarkerTooltip.remove();
				}

				if (locationsMap) {
					futureState.hide.forEach((location) => {
						if (location.classList.contains('location__marker')) {
							location.closest('div').classList.add('marker-disable');
						}
					});

					futureState.show.forEach((location) => {
						if (location.classList.contains('location__marker')) {
							location.closest('div').classList.remove('marker-disable');
						}
					});
				}
			},
			onMixEnd: function (state) {
				if (amountOfLocations) {
					amountOfLocations.textContent = state.totalShow / 2;
				}
			},
		},
	});

	// Fixed add maskers to map
	if (delay == true) {
		setTimeout(() => {
			filtermixer.forceRefresh();
		}, 2000);
	}

	if (sectionSelectboxes.length > 1) {
		sectionSelectboxes.forEach((item) => {
			new SlimSelect({
				select: item,
				showSearch: false,
				onChange: (info) => {
					// It works only on the search page
					if (info.value === 'desc' || info.value === 'asc') {
						filtermixer.sort(`price-per-night:${info.value}`);
					}
				},
			});
		});
	} else if (sectionSelectbox) {
		new SlimSelect({
			select: sectionSelectbox,
			showSearch: false,
			onChange: (info) => {
				filtermixer.filter(info.value);
			},
		});
	}

	// Filter by hash - blog
	if (mixContainer.classList.contains('all-posts__list')) {
		const categoryHash = window.location.hash.substr(1);

		if (!categoryHash) return;
		if (categoryHash == 'all') {
			filtermixer.filter(categoryHash);
		} else {
			filtermixer.filter(`.term-${categoryHash}`);
		}
	}
};
